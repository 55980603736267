var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width of the Product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height of the Product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth of the Product (mm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#efficiency-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "efficiency-filter"
            }
          },
          [_vm._v(" Energy Efficiency ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "efficiency-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energyEfficiencies,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy efficiencies",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energyEfficiencyChanged },
              model: {
                value: _vm.energyEfficiency,
                callback: function($$v) {
                  _vm.energyEfficiency = $$v
                },
                expression: "energyEfficiency"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#installation-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "installation-filter"
            }
          },
          [_vm._v(" Installation Type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "installation-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.installations,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select installation types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.installationChanged },
              model: {
                value: _vm.installation,
                callback: function($$v) {
                  _vm.installation = $$v
                },
                expression: "installation"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#control-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Type of control ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "control-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.controlTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select control types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.controlTypeChanged },
              model: {
                value: _vm.controlType,
                callback: function($$v) {
                  _vm.controlType = $$v
                },
                expression: "controlType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#connectivity-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Connectivity type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "connectivity-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.connectivities,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select connectivity types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.connectivityChanged },
              model: {
                value: _vm.connectivity,
                callback: function($$v) {
                  _vm.connectivity = $$v
                },
                expression: "connectivity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#source-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "source-filter"
            }
          },
          [_vm._v(" Energy Sources ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "source-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energySources,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy sources",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energySourceChanged },
              model: {
                value: _vm.energySource,
                callback: function($$v) {
                  _vm.energySource = $$v
                },
                expression: "energySource"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#plug-type-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "plug-type-filter"
            }
          },
          [_vm._v(" Plug type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "plug-type-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.plugTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select plug types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.plugTypeChanged },
              model: {
                value: _vm.plugType,
                callback: function($$v) {
                  _vm.plugType = $$v
                },
                expression: "plugType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#cooking-mode-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "cooking-mode-filter"
            }
          },
          [_vm._v(" Cooking modes ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "cooking-mode-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.cookingModes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select cooking modes",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.cookingModeChanged },
              model: {
                value: _vm.cookingMode,
                callback: function($$v) {
                  _vm.cookingMode = $$v
                },
                expression: "cookingMode"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#cleaning-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "cleaning-filter"
            }
          },
          [_vm._v(" Integrated cleaning system ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "cleaning-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.cleaningSystems,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select cleaning systems",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.cleaningSystemChanged },
              model: {
                value: _vm.cleaningSystem,
                callback: function($$v) {
                  _vm.cleaningSystem = $$v
                },
                expression: "cleaningSystem"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key Features ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.autopilot,
                        expression: "autopilot"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-0" },
                    domProps: {
                      checked: Array.isArray(_vm.autopilot)
                        ? _vm._i(_vm.autopilot, null) > -1
                        : _vm.autopilot
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.autopilot,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.autopilot = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.autopilot = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.autopilot = $$c
                          }
                        },
                        _vm.autopilotChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-0" } }, [
                    _vm._v("Autopilot")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.interiorLight,
                        expression: "interiorLight"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.interiorLight)
                        ? _vm._i(_vm.interiorLight, null) > -1
                        : _vm.interiorLight
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.interiorLight,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.interiorLight = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.interiorLight = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.interiorLight = $$c
                          }
                        },
                        _vm.interiorLightChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("Interior light")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#series-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "series-filter"
            }
          },
          [_vm._v(" Product series ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "series-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.serieses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select serieses",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.seriesChanged },
              model: {
                value: _vm.series,
                callback: function($$v) {
                  _vm.series = $$v
                },
                expression: "series"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#colour-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "colour-filter"
            }
          },
          [_vm._v(" Main Colour of Product ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "colour-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#appliance-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "appliance-filter"
            }
          },
          [_vm._v(" Smart Appliance ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "appliance-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remoteDiagnostics,
                        expression: "remoteDiagnostics"
                      }
                    ],
                    attrs: { type: "checkbox", id: "appliance-0" },
                    domProps: {
                      checked: Array.isArray(_vm.remoteDiagnostics)
                        ? _vm._i(_vm.remoteDiagnostics, null) > -1
                        : _vm.remoteDiagnostics
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.remoteDiagnostics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.remoteDiagnostics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.remoteDiagnostics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.remoteDiagnostics = $$c
                          }
                        },
                        _vm.remoteDiagnosticsChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "appliance-0" } }, [
                    _vm._v("Remote Diagnostics")
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }